<template>
  <DelayHydration>
    <div class="pb-7 pt-10 lg:pb-16 lg:pt-14">
      <UiHeader
        v-bind="props"
        class="text-primary"
      />
      <div
        class="-mx-8 hidden gap-6 overflow-x-auto pb-5 hide-scrollbar lg:mx-0 lg:grid lg:grid-cols-4"
      >
        <LazyUiSocialFeedItem
          v-for="listItem in props.data"
          :key="listItem.uid"
          :uid="listItem.uid"
          :url="listItem.url"
          :image="listItem.image"
          :title="listItem.title ? listItem.title : 'Facebook Rawlplug'"
          :date="listItem.date.date"
          :message="listItem.message"
          :likes="listItem.likes"
          :comments="listItem.comments"
          :labels="labels"
          class="last:mr-4 xl:last:mr-0"
        />
      </div>
      <div class="relative -mr-8 lg:mr-0 lg:hidden">
        <UiSliderHorizontal
          class="swiper-main"
          classes="swiper-main scrollbar-primary"
        >
          <LazyUiSocialFeedItem
            v-for="listItem in props.data"
            :key="listItem.uid"
            :uid="listItem.uid"
            :url="listItem.url"
            :image="listItem.image"
            :title="listItem.title ? listItem.title : 'Facebook Rawlplug'"
            :date="listItem.date.date"
            :message="listItem.message"
            :likes="listItem.likes"
            :comments="listItem.comments"
            :labels="labels"
            class="last:mr-4 xl:last:mr-0"
          />
        </UiSliderHorizontal>
      </div>
    </div>
  </DelayHydration>
</template>

<script setup lang="ts">
import type { UiHeaderProps } from '@ui/components/UiHeader/useUiHeader'
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import type { T3SocialFeedtem } from './T3CeHeadlesssocialfeed_Feed.types'

interface T3CeSocialFeed extends UiHeaderProps {
  data: T3SocialFeedtem[]
}

const props = defineProps<T3CeSocialFeed>()

const { t } = useI18n()
const labels = computed(() => {
  return {
    share: t('cms_share_our_blog_post'),
  }
})
</script>
